<template>
  <div class="card mb-4">
    <loader v-if="isSearchLoading" style="z-index:10000" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <h6>List of Users</h6>
        </div>
        <div class="col-md-4 text-end">
            <router-link :to="{ name: 'Add User' }">
              Add User <i class="fas fa-plus-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">Add User</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" v-if="isLoading==false && message==''">
        <div class="filter-records-holder">
          <div class="row">
            <div class="col-md-4">
              <div class="px-4 py-4 text-xs">
                <b>Total Records :</b> {{rows}}, <b>Per Page :</b> {{perPage}}
              </div>
            </div>
            <div class="col-md-8">
              <div class="py-3  text-xs">
                <div class="row">
                    <div class="col-md-12">
                      <form @submit.prevent="SubmitSearchForm">
                        <div class="row">
                          <div class="col-md-5">
                            <input type="text" name="searchInput" v-model="searchInput" class="form-control" placeholder="Search Input" required />
                          </div>
                          <div class="col-md-3">
                            <select type="text" name="searchBy" class="form-control" v-model="searchBy" required>
                              <option value="">... By</option>
                              <option value="name">Name</option>
                              <option value="email">Email</option>
                            </select>
                          </div>
                          <div class="col-md-2">
                            <VsudButton color="info" class="form-control" variant="gradient"> Submit </VsudButton>
                          </div>
                          <div class="col-md-2">
                            <span class="btn mb-0 bg-gradient-secondary btn-md null null form-control" @click="SearchReset">Reset</span>
                          </div>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Role
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date created
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in UserData"  :key="user.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="imgDef"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="index"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{user.name}}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{user.email}}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">Position</p>
                <p class="text-xs text-secondary mb-0">
                  <span v-if="user.primaryRole.name=='supervisor'">
                    state_supervisor
                  </span>
                  <span v-else>
                    {{user.primaryRole.name}}
                  </span>
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <vsud-badge color="success" variant="gradient" size="sm"
                  >Active</vsud-badge
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{user.createdAt}}</span
                >
              </td>
              <td class="align-middle">
                <router-link
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  :to="{name:'Edit User' , params: {id:user.id}}"
                  >Edit</router-link
                >
              </td>
            </tr>

          </tbody>
        </table>
        <div class="p-3">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
        </div>
      </div>
      <div class="spiner-holder" v-else-if="isLoading==true && message==''">
        <vsud-spinner></vsud-spinner>
      </div>
      <div class="nodata-holder" v-else>
        <vsud-nodata :message="message"></vsud-nodata>
      </div>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import imgDef from "@/assets/img/team-5.png";
import AuthService from '@/services/auth.service.js';

export default {
  name: "users-table",
  data(){
    return {
      UserData : [],
      imgDef,
      isLoading : false,
      isSearchLoading : false,
      message : '',
      perPage: 0,
      currentPage: 0,
      rows : 0,
      searchInput : '',
      searchBy : '',
    }
  },
  components: {
    VsudAvatar,
    VsudBadge,
    VsudSpinner,
    VsudNodata,
    VsudButton
  },
  created(){
    this.GetUsers();
  },
  methods :{
    async GetUsers(page='',searchBy='',searchInput=''){
       
       this.isLoading = (searchBy && searchInput)?  false : true;
       this.isSearchLoading = (searchBy && searchInput)? true : false;
       const nameUrl = (searchBy=='name') ? '&name='+searchInput : '';
       const emailUrl = (searchBy=='email') ? '&email='+searchInput : '';
       const pageUrl = (page!==undefined&&page>0)? '&page='+page : '';

       try{

          const response = await AuthService.getUsers('?perPage=100'+pageUrl+nameUrl+emailUrl);
          console.log(response);
          if(response.data.length>0)
          {
            this.UserData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;
          }
          else
          {
            this.message = 'No data , please try again';
          }

          this.isLoading = false;
          this.isSearchLoading = false;
       }
       catch(error){

        if(error)
        { 
          this.isLoading = false;
          console.log(error);
          this.message = 'An Error Occured';
        }

       }
    },

    handlePageChange(value) 
    {
      // # Debug :
      // console.log(value);
      // console.log(this.currentPage);
      // this.$router.push("/beneficiaries/pages/"+value);

      this.GetUsers(value,this.searchBy,this.searchInput);
    },

    SubmitSearchForm()
    {
      // # Debug : 
      // const searchBy = (this.searchBy!=='')? this.searchBy : '';
      // const searchInput = (this.searchInput!=='')? this.searchInput : '';
      // console.log(this.searchBy); console.log(this.searchInput);

      this.GetUsers('',this.searchBy,this.searchInput);
    },

    SearchReset()
    {
      this.GetUsers('','','');
    }

  }
};
</script>
